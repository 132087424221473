@import "@/styles/variables.scss";

.birthdayForm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: $spacing-4;

  &_birthdayFields {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-3;

    &__lg {
      gap: $spacing-6;
    }
  }

  &_birthdaySelect {
    width: 80px;
  }

  &_birthdayInput {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;
  }

  &_birthdayLabel {
    color: $color-gray-dark;
    font-size: $font-size-s;
  }

  &_birthdayError {
    flex-basis: 100%;
    color: $color-red;
    font-size: $font-size-s;
  }
}

