@import "@/styles/variables.scss";

.genderSelect {
  .genderSelect__control--is-focused,
  .genderSelect__control--is-focused:hover {
    border-color: $color-green-main;
    box-shadow: 0 0 6px 2px #417054;
  }

  .genderSelect__option:hover {
    background-color: $color-gray;
  }

  .genderSelect__option--is-selected,
  .genderSelect__option--is-selected:hover {
    background-color: $color-green-main;
    color: $font-color-light;
  }

  &__lg {
    .genderSelect__control {
      padding: 9px 0;
    }
  }
}

