@import "@/styles/variables.scss";

.birthdaySelect {
  width: 80px;

  .birthdaySelect__control--is-focused,
  .birthdaySelect__control--is-focused:hover {
    border-color: $color-green-main;
    box-shadow: 0 0 6px 2px #417054;
  }


  .birthdaySelect__option:hover {
    background-color: $color-gray;
  }

  .birthdaySelect__option--is-selected,
  .birthdaySelect__option--is-selected:hover {
    background-color: $color-green-main;
    color: $font-color-light;
  }

  &__lg {
    .birthdaySelect__control {
      padding: 12px 0;
      text-align: center;
    }

    .birthdaySelect__input-container,
    .birthdaySelect__input {
      text-align: center;
    }

    .birthdaySelect__input-container {
      grid-template-columns: 0 1fr;
    }

    .birthdaySelect__input {
      display: inline-block;
      width: 100%;
    }
  }
}

